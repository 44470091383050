.ai-suggestion-switcher{
    position: absolute;
}

.with-ai-suggestion-switcher {
    padding-right: 68px !important;
}

.ai-suggestion-switcher {
    position: absolute;
    right: 24px;
    top: calc(50%);
}

textarea.form-control,
input[type="text"].form-control{
    background: #fff !important;
}
/* Add this CSS to your stylesheet or component */
.disabled-card {
	pointer-events: none;
	opacity: 0.6; /* You can adjust the opacity to your preference */
}

.logo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.logo-container p {
	font-size: 18px;
	width: fit-content;
}
.background {
	background: url('./../../../assets/images/landing-background.png');
	background-size: 100%;
	color: #fff;
	padding-bottom: 180px !important;
}

.section-one {
	margin-top: 70px !important;
}

.title {
	font-size: 42px;
	color: #fff;
	font-weight: 700;
}

.sub-title {
	font-size: 22px;
}

.circle {
	position: relative;
	width: 300px;
	height: 300px;
	border-radius: 100%;
	border: 7px solid #fff;
	box-shadow: 4px 4px 40px #fff;
	color: #fff;
}
.second-circle {
	position: absolute;
	left: -7px;
	top: -7px;
	width: 300px;
	height: 300px;
	border-radius: 100%;
	border: 7px solid #fff;
	-moz-box-shadow: inset 5px 5px 40px #fff;
	-webkit-box-shadow: inset 4px 4px 40px #fff;
	box-shadow: inset 4px 4px 40px #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 26px;
	color: #fff;
}

.ml-4 {
	margin-left: 35px;
}

.text-section-2 {
	font-size: 32px;
}

.box-container {
	background-color: #fff;
	padding: 50px 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 260px;
}

.box-container .title {
	color: #d73cbe;
	font-size: 30px !important;
	line-height: 40px;
	text-align: center;
}

.box-container .button {
	background-color: #2c1e4a;
	width: 167px !important;
}

.mt-10 {
	margin-top: 180px !important;
}

.second-section {
	position: relative;
	left: -50px;
}

.third-canva {
	position: absolute;
	transform: rotate(58deg);
	width: 350px;
	z-index: 1;
}
.fourth-canva {
	position: absolute;
	transform: rotate(-108deg);
	width: 350px;
	left: 259px;
	top: 100px;
}

.fifth-canva {
	width: 100%;
}

.thirt-section .button {
	background-color: #d73cbe;
	border: none;
	border-radius: 10px;
	width: 180px;
	margin: 10px;
	height: 58px;
	font-weight: 700;
	color: #fff;
}

.section-four .title {
	font-size: 48px;
}

.sixth-canva {
	width: 100%;
	transform: rotate(-190deg);
}

.seven-canva {
	width: 300px;
	position: absolute;
	z-index: 1;
	right: 280px;
}

.eight-canva {
	width: 300px;
	transform: rotate(180deg);
	position: absolute;
	top: -170px;
	z-index: 0;
}

.fw-700 {
	font-weight: 700 !important;
}

.fs-18 {
	font-size: 18px;
}

.fs-28 {
	font-size: 28px;
}

.fs-20 {
	font-size: 20px;
	text-decoration: underline !important;
	color: #fff;
}

.box-container .sub-title {
	color: #3c205d;
	font-size: 18px;
}

.start-page-third-party {
	width: 100%;
	padding-top: 170px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	margin: 0 auto;

	.start-page-title {
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 50px;
		color: #000000;
	}

	.options {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 80px;

		.option-item {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 350px;
			min-height: 150px;
			img {
				display: block;
				width: 150px;
				height: 150px;
				display: block;
			}
			.option-item-button {
				text-align: center;
				width: 250px;
				border-radius: 100px;
				background-color: var(--color-primary) !important;
				color: var(--color-body) !important;
				font-size: 18px;
				line-height: 50px;
				font-weight: bold;
				margin-top: 20px;
			}
			.option-item-description {
				margin-top: 35px;
				color: #000000;
				font-size: 15px;
			}
		}
	}
}

.start-page-admin {
	.options {
		div.option {
			opacity: 0.7;
			cursor: not-allowed;
		}
	}

	.gif-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo-container {
		.logos {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.logo {
				display: inline-block;
				max-width: 100px;
				min-height: 60px;
				max-height: 60px;
				object-fit: contain;
				&.diss-co-logo {
					margin-right: 20px;
				}
			}
		}
		svg {
			fill: #f5f5ff;
			width: 30px;
			height: 30px;
		}
		.signout-button{
			font-size: 40px;
		}
	}

	.follow-links {
		margin-right: 22px;
		font-size: 16px;
		color: #fff;
	}
}


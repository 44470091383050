
@import "./variables";

@import "./components/index";

@import "./pages/admin/index";

@import "./pages/thirdparty/index";    

html[data-module='gdpr']{


    body{
        background-color: $main-bg-color;
        font-family: $font-family !important;
        font-size: $font-size-base;
    }

    .main-content{
        overflow: visible;
    }

    iframe {
        display: none !important;
    }

    .ellipsis {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &.l-1{
            -webkit-line-clamp: 1;
        }
    
        &.l-2{
            -webkit-line-clamp: 2;
        }
    
        &.l-3{
            -webkit-line-clamp: 3;
        }
    }
}
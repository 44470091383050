.nav-tabs{
    &.custom-style-1{
        display: inline-flex;
        background-color: #fff;
        border: none;

        .nav-item{
            background-color: #D1D2D6;
        }

        .nav-item:has(+.active){
            border-bottom-right-radius: 8px;
            .nav-link{
                border-bottom-right-radius: 8px;
            }
        }

        .nav-item:has(> .active) + .nav-item{
            border-bottom-left-radius: 8px;
            .nav-link{
                border-bottom-left-radius: 8px;
            }
        }

        .nav-link{
            font-size: $font-size-md !important;
            font-weight: $font-weight-light;
            color: #000;
            border: none;
            border-radius: 0px;
            padding: 23px !important;
            font-family: $font-family !important;

            &.active{
                font-weight: $font-weight-normal;
                background-color: #fff;

            }

            &:hover{
                color: #000 !important;
            }
        }
    }
}
// .report-details-box {
//   font-size: $font-size-md;
//   border: 1px solid #5664d2;
//   // padding: 16px 75px 16px 75px;
//   padding: 15px 5px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-flow: column;
//   // min-height: 295px;
//   font-weight: $font-weight-light;
//   background-color: #5664d21a;
//   background-image: url("src/modules/data-protection/assets/images/details-background.svg");
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;

//   .report-details-row {
//     display: flex;
//     justify-content: center;
//     align-items: baseline;
//     flex-flow: row;
//     flex-wrap: wrap;
//     gap: 4rem;

//     .report-details-col {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: column;
//       background-color: red;
//       gap: 2rem;
//       // margin: 2rem 14px 2rem 14px;
//       // margin: 1em 14px;
//       .detail-title {
//         // margin-bottom: 14px;
//         font-weight: $font-weight-normal;
//         white-space: nowrap; /* Prevents the text from wrapping to the next line */
//         overflow: hidden; /* Ensures that the overflowed text is hidden */
//         text-overflow: ellipsis; /* Adds an ellipsis to signify that the text is overflowing */
//         display: block; /* Optional: Ensures the span behaves like a block element, may be useful depending on your layout */
//       }

//       .detail-value {
//         white-space: nowrap; /* Prevents the text from wrapping to the next line */
//         overflow: hidden; /* Ensures that the overflowed text is hidden */
//         text-overflow: ellipsis; /* Adds an ellipsis to signify that the text is overflowing */
//         display: block; /* Optional: Ensures the span behaves like a block element, may be useful depending on your layout */
//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }
//       }
//     }

//     // &:last-child {
//     //   margin-bottom: 0px;
//     // }
//   }

//   @media (max-width: 768px) { // Adjust the max-width value to target mobile devices
//     .report-details-row {
//       flex-flow: column; // Change flex direction to column

//       .report-details-col {
//         width: 100%; // Ensure each column takes the full width
//         margin: 10px 0; // Adjust margin for spacing between rows
//       }
//     }
//   }
// }

// .report-details-box {
//   font-size: $font-size-md;
//   border: 1px solid #5664d2;
//   padding: 5px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-flow: column;
//   font-weight: $font-weight-light;
//   background-color: #5664d21a;
//   background-image: url("src/modules/data-protection/assets/images/details-background.svg");
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;

//   .report-details-row {
//     display: flex;
//     justify-content: center;
//     align-items: baseline;
//     flex-flow: row;
//     flex-wrap: wrap;

//     .report-details-col {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: column;
//       margin: 5px 5px;

//       .detail-title {
//         margin-bottom: 14px;
//         font-weight: $font-weight-normal;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;
//       }

//       .detail-value {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;

//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }
//       }
//     }
//   }

//   @media (max-width: 768px) { // Adjust the max-width value to target mobile devices
//     .report-details-row {
//       flex-flow: column; // Change flex direction to column

//       .report-details-col {
//         width: 100%; // Ensure each column takes the full width
//         margin: 10px 0; // Adjust margin for spacing between rows
//       }
//     }
//   }
// }

// .report-details-box {
//   font-size: $font-size-md;
//   border: 1px solid #5664d2;
//   padding: 5px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-flow: column;
//   font-weight: $font-weight-light;
//   background-color: #5664d21a;
//   background-image: url("src/modules/data-protection/assets/images/details-background.svg");
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;

//   .report-details-row {
//     display: flex;
//     justify-content: center;
//     align-items: baseline;
//     flex-flow: row;
//     flex-wrap: wrap;

//     .report-details-col {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: column;
//       margin: 5px 5px;

//       .detail-title {
//         margin-bottom: 14px;
//         font-weight: $font-weight-normal;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;
//       }

//       .detail-value {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;

//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }
//       }
//     }
//   }

//   @media (max-width: 768px) {
//     .report-details-row {
//       flex-flow: column;

//       .report-details-col {
//         width: 100%;
//         margin: 10px 0;
//       }
//     }
//   }

//   @media (min-width: 769px) and (max-width: 1024px) {
//     .report-details-row {
//       flex-flow: row wrap;
//       justify-content: space-between;

//       .report-details-col {
//         width: 45%; // Adjust to fit two columns per row on tablet size
//         margin: 10px 5px;
//       }
//     }
//   }
// }

// .report-details-box {
//   font-size: $font-size-md;
//   border: 1px solid #5664d2;
//   padding: 5px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-flow: column;
//   font-weight: $font-weight-light;
//   background-color: #5664d21a;
//   background-image: url("src/modules/data-protection/assets/images/details-background.svg");
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;

//   .report-details-row {
//     display: flex;
//     justify-content: center;
//     align-items: baseline;
//     flex-flow: row;
//     flex-wrap: wrap;

//     .report-details-col {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: column;
//       margin: 5px 5px;

//       .detail-title {
//         margin-bottom: 14px;
//         font-weight: $font-weight-normal;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;
//       }

//       .detail-value {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: block;

//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }
//       }
//     }
//   }

//   @media (max-width: 768px) {
//     .report-details-row {
//       flex-flow: column;

//       .report-details-col {
//         width: 100%;
//         margin: 10px 0;
//       }
//     }
//   }

//   @media (min-width: 769px) and (max-width: 1024px) {
//     .report-details-row {
//       flex-flow: row wrap;
//       justify-content: space-between;

//       .report-details-col {
//         width: 30%; // Adjust to fit three columns per row on tablet size
//         margin: 10px 1.66%; // Adjust margin to space out columns evenly
//       }
//     }
//   }
// }


.report-details-box {
  font-size: $font-size-md;
  border: 1px solid #5664d2;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  font-weight: $font-weight-light;
  background-color: #5664d21a;
  background-image: url('../../../images/details-background.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .report-details-row {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 20px; /* Added margin-bottom to increase space between rows */

    &:first-child {
      margin-top: 20px; /* Remove margin-bottom for the last row */
    }

    &:last-child {
      margin-bottom: 0; /* Remove margin-bottom for the last row */
    }

    .report-details-col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      margin: 10px 5px; /* Adjusted margin to increase space between columns and rows */

      .detail-title {
        margin-bottom: 14px;
        font-weight: $font-weight-normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .detail-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .report-details-row {
      flex-flow: column;

      .report-details-col {
        width: 100%;
        margin: 10px 0; /* Adjusted margin for mobile view */
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .report-details-row {
      flex-flow: row wrap;
      justify-content: space-between;

      .report-details-col {
        width: 30%; /* Adjust to fit three columns per row on tablet size */
        margin: 10px 1.66%; /* Adjust margin to space out columns evenly */
      }
    }
  }

  @media (min-width: 1025px) {
    .report-details-row {
      justify-content: flex-start; /* Align items to the start */
      
      .report-details-col {
        margin: 10px 15px; /* Increased margin for laptop view */
      }
    }
  }
}







.loading-section {
  background-color: #eeeffa;
  border-radius: 8px;
}


.incident-table{

  .dt-list-col-bullet {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--color-primary);
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  
    font-weight: bold;
    text-transform: uppercase;
    line-height: 30px;
  }
  .dt-list-col-bullet-text {
    margin-left: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgb(80, 93, 105);
  }

  .dt-task-id {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 100%;
    display: block;
    line-height: 25px;
    font-family: 'Inter', sans-serif;
    &.dt-skeleton {
      display: inline-block;
      width: auto;
      min-width: 50%;
      height: 25px;
    }

    &:not(.dt-skeleton):hover {
      color: var(--bs-primary);
    }
  }

  .dt-task-title {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 100%;
    display: block;
    line-height: 25px;
    font-family: 'Inter', sans-serif;
    &.dt-skeleton {
      display: inline-block;
      width: auto;
      min-width: 50%;
      height: 25px;
    }

    &.hoverable:hover {
      color: var(--bs-primary);
    }
  }

  .dt-task-creation-date,
  .dt-task-deadline-date {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 100%;
    display: block;
    line-height: 25px;
    font-family: 'Inter', sans-serif;
    &.dt-skeleton {
      display: inline-block;
      width: auto;
      min-width: 50%;
      height: 25px;
    }
  }

  .dt-task-priority {
    text-transform: capitalize;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
  }

  .dt-task-status {
    padding: 0 10px;
    line-height: 25px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    width: auto;
    &.dt-skeleton {
      min-width: 50%;
      height: 25px;
    }
  }

  .dt-risk-rating-bullet {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;
  }

  .dt-list-col-result-id {
    font-weight: bold;
    text-transform: uppercase;
    min-width: 50%;
    height: 25px;
  }

  .dt-list-col-date {
    font-weight: 500;
    &.dt-skeleton {
      width: 80%;
      height: 25px;
    }
  }
}

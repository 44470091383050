.table-scroll-container {
	overflow-x: auto;
	display: block;
	max-width: 100%; // You can adjust this as needed
  
	.custom-table {
		display: flex;
		flex-direction: column;
		min-width: 100%;
		width: max-content; 
		.table-header-container {
			height: 53px;
			color: #000000;
			border-bottom: 1px solid #f5f4f7;
			font-weight: bold;
		}

		.table-header-item {
			flex-grow: 1;
			padding: 16px 10px;
		}

		.row-container {
			display: flex;
			flex-direction: row;
			height: 56px;
			color: #1a2052;
			transition: background-color 0.1s ease-out;
		}

		.row-container:hover {
			background-color: #f5f5f5 !important;
		}

		.item-container {
			padding: 16px 10px;
			white-space: nowrap; /* Keep the text on a single line */
			overflow: hidden; /* Hide overflow text */
			text-overflow: ellipsis; /* Add ellipsis to overflow text */
			display: block; /* Or flex, depending on your layout */
			width: 100%; /* Ensure it respects the parent's width */
		}

		
	}
}
.page-content{
    font-family: $font-family !important;
    font-size: $font-size-base;
    font-weight: $font-weight-light;

    .wizard-steps-container{
        box-shadow: 0px 4px 4px 0px $box-shadow-color;
        border-radius: 8px;
        border: none;
    
        .twitter-bs-wizard{
            position: relative;
    
            .twitter-bs-wizard-nav{
                &::before{
                    content: none;
                }
    
                .nav-item{
                    position: relative;

                    .nav-link{
                        .step-title{
                            font-weight: $font-weight-light;
                        }

                        &.current{
                            .step-title{
                                font-weight: $font-weight-bold;
                            }
                        }
                    }
                    
                    &:last-child{
                        &::before{
                            content: none;
                        }
                    }
    
                    &::before{
                        content: '';
                        background: #E9ECEF;
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        left: 50%;
                        top: 31px;
                    }
    
                    &.completed{
                        &::before{
                            background: #5664D2;
                        }
                    }
    
                    .nav-link{
                        &.current{
                            .step-number{
                                &::before{
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    border: 1px solid #fff;
                                    display: block;
                                    border-radius: 100%;
                                }
                            }
                        }
                    }
                }
            }
    
            #bar {
                position: absolute;
                top: 50%;
                width: 100%;
            }
        }
    }
    
    form#tpdd{
        .accordion-item{
            margin-bottom: 16px;
            border: none !important;
            border-radius: 8px !important;
            overflow: hidden;
            box-shadow: 0px 4px 4px 0px $box-shadow-color;
            position: relative;
    
            .form-control {
                background: transparent;
            }
    
            label {
                color : $labels-font-color;
                font-size: $labels-font-size;
                font-weight: $labels-font-weight;
            }
    
            &.active{
                .accordion-header button{
                    background: #F5F6FF;
                    box-shadow: none;
                }
            }
    
            .accordion-collapse {
                background-color: #F5F6FF;
            }
    
            .accordion-header{
                background: #F5F6FF;
                
                button{
                    color: #000;
                    font-weight: bold;
                    padding: 16px;
                    height: 92px;
                    border-radius: 8px;
    
                    .section-header{
                        width: 100%;
    
                        > * {
                            position: relative;
                            z-index: 1;
                            background: transparent;
                            color : $questionnaire-headers-font-color;
                            font-size: $questionnaire-headers-font-size;
                            font-weight: $questionnaire-headers-font-weight;
                        }
    
                        .completed-percent{
                            position: absolute;
                            width: 0%;
                            height: 100%;
                            background: #59C94F4D;
                            top: 0px;
                            left: 0px;
                            z-index: 0;
                            transition: 0.7s ease all;
                        }
                    }
                }
            }
        }
    }

    .confirm-form-sections-container{
        box-shadow: 0px 4px 4px 0px $box-shadow-color;
        border-radius: 12px;
        border: none;
        background-color: #fff;
        padding: 16px;
        color: $font-color-base;
        margin-bottom: 1.5rem;

        .section{
            border: 1px solid #828282;
            padding: 16px;
            border-radius: 8px;
            margin-bottom: 16px;

            .section-header{
                padding: 16px;

                img{
                    width: 60px;
                    margin-right: 8px;
                }

                span{
                    font-size: $font-size-md;
                    font-weight: $font-weight-normal;
                }
            }

            .section-body{
                padding: 0px 80px 0px 80px;
                margin-top: 15px;

                .question{
                    .sub-question-container {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;
                        margin-top: 12px;
                        padding: 0px 0px;
                        margin-left: 15px;
                        margin-bottom: 15px;

                        .question{
                            flex-basis: 50% !important;
                            flex: 2 1;
                        }
                    }

                    .question-title{
                        font-weight: $font-weight-light;
                        margin-bottom: 16px;
                    }

                    .files-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;


                        .file-preview{
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-items: start;
                            flex-basis: 33.33%;
                            flex: 2 1;
    
                            .file-name{
                                margin-bottom: 4px;
                            }
    
                            img{
                                width: 100px;
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
    }

    .declaration-confirm-form{
        .card-title,
        .card-body,
        .form-check label{
            font-size: 16px;
            color: #4F4F4F;
            font-weight: 400;
        }

        .form-check label{
            color: #000;
        }
    }
}